import { QRCodeCanvas } from 'qrcode.react';
import { ChangeEvent, Ref, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './App.css';

function App() {

  const [marca, setMarca] = useState('IMPULSE EC800 1X60L DRM EC');
  const [presentacion, setPresentacion] = useState('60 L');
  const [pvp, setPvp] = useState('$ 2,660.40 ');

  const data = [
    {
      marca: 'IMPULSE EC800 1X60L DRM EC', 
      presentacion: '60 L', 
      pvp: '$ 8.000 '
    },
    { 
      marca: 'IMPULSE EC800 4X5L BOT EC', 
      presentacion: '5 L', 
      pvp: '$ 221.60 '
    },
    { 
      marca: 'LUNA TRANQUILITY SC500 1X50L DRM EC', 
      presentacion: '5 L', 
      pvp: '$ 9999999 '
    },
  ];

  const [url, setUrl] = useState("");

  const qrRef:any = useRef(<div></div>);

  const downloadQRCode = (e:SyntheticEvent) => {
    e.preventDefault();
    let canvas:any = qrRef.current!.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `qr-code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    setUrl("");
  };

  const qrCodeEncoder = (e:ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={url}
      size={300}
      bgColor={"#00ff00"}
      level={"H"}
    />
  );

  const params = useParams();
  /*
  useEffect(() => {
    const id = Number(params.id);
    setMarca(data[id].marca);
    setPresentacion(data[id].presentacion);
    setPvp(data[id].pvp);
    setUrl(window.location.href)
  }, []);
*/
  return (
    <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <main className="px-3">
          <h1 className=''>
            {marca ? marca : 'Marca...'}
          </h1>
          <h1>   
            {presentacion ? presentacion : 'Presentación...'}
          </h1>
          <h1>    
            {pvp ? pvp : '$...'}
          </h1>
        </main>

        <div className="qrcode__container">
          <div ref={qrRef}>{qrcode}</div>
          <div className="input__group">
            <form onSubmit={downloadQRCode}>
              <label></label>
              <input
                type="text"
                value={url}
                onChange={qrCodeEncoder}
                placeholder={url}
              />
              <button type="submit" disabled={!url}>
                Download QR code
              </button>
            </form>
          </div>
        </div>
      
        <footer className="mt-auto text-white-50">
          <p>Politica de <Link to="/" className="text-white">Privacidad</Link><br></br> <Link to="" className="text-white">Bayer Colombia</Link></p>
        </footer>
      </div>
  );
}

export default App;
